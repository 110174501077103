<template>
	<div v-if="isSiemens && showBanner && hasNonControlDevices">
		<ws-notification-message
			type="info"
			class="m-0 p-3"
			:show-close-button="true"
			is-full-screen
		>
			&#128226;
			<i18n-t
				:keypath="'banner.schedules.try-control-siemens.body'"
				tag="span"
				scope="global"
			>
				<template #goToLink>
					<router-link :to="{ name: 'organization-devices' }">
						{{ $t("sidebar-menu-item.company") }} >
						{{ $t("licenses") }}
					</router-link>
				</template>
				<template #blogLink>
					<a :href="blogLink" rel="noopener" target="_blank">
						<span>
							{{ $t("blog") }}
						</span>
					</a>
				</template>
			</i18n-t>
		</ws-notification-message>
	</div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import AuthService from "@/services/Auth.service.js";
import DevicesService from "@/services/v1/Device.service.js";
import { useI18n } from "vue-i18n";
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import { useClientName } from "@/plugins/client-name/client-name.plugin.js";

const { t: $t } = useI18n();
const { clientName } = useClientName();
const isSiemens = computed(() => clientName === "siemens");
const { error: $logError } = useLogger();
const route = useRoute();

const hasNonControlDevices = ref(false);
const showBanner = computed(() =>
	["boxes-map", "boxes", "hubs"].includes(route.name)
);
let user = null;
onMounted(async () => {
	user = await AuthService.getUserInfo();
	await checkIfThereAreNonControlDevices();
});
async function checkIfThereAreNonControlDevices() {
	try {
		const devices = await DevicesService.getDevices({ showSpinner: false });

		const organizationIdsUserIsAdmin = Object.entries(
			user?.privileges || {}
		).reduce((acc, [organizationId, privileges]) => {
			const listPrivileges = privileges.flatMap(
				(privilege) => privilege.action
			);
			const hasAccess =
				listPrivileges.includes("Admin") ||
				listPrivileges.includes("DevicesManagementWrite");
			if (!hasAccess) {
				return acc;
			}
			return [...acc, organizationId];
		}, []);
		hasNonControlDevices.value =
			devices?.filter(
				(device) =>
					device?.subscription?.current?.ratePlanDetails?.range !==
						"CONTROL" &&
					organizationIdsUserIsAdmin.includes(device?.organizationId)
			).length > 0;
	} catch (error) {
		$logError("failed getting devices", error);
		hasNonControlDevices.value = false;
	}
}

const blogLink = computed(() => {
	return "https://blog.siemens.com/2024/06/siemens-connect-box-latest-update-introduction-of-the-control-functionality/";
});
</script>
